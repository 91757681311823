.searchBar_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  /* justify-content: flex-start; */
  border-radius: 8px;
  padding: 0.2rem 1rem;
  background-color: var(--white);
  gap: 0.1rem;
}

.searchBar_container img {
  width: 15px;
  height: 18px;
}

.locationSection_searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  row-gap: 1.5rem;
  /* width: 100%; */
  background-color: var(--white);
}

.locationSection_searchbar p {
  color: var(--text-grey);
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
}

.seachVenue_section_searchbar input {
  border: none;
  font-size: 14px;
  color: var(--text-grey);
  margin-left: 0.3rem;
  outline: white !important;
  background-color: var(--white);
  width: 100%;
}

.seachVenue_section_searchbar input:focus {
  outline: none !important;
  box-shadow: none;
}

.seachVenue_section_searchbar input::placeholder {
  font-family: "Roboto";
  font-size: 14px;
  color: var(--text-grey);
}

.seachVenue_section_searchbar {
  background-color: var(--white);
  align-items: center;
  display: flex;
  padding: 0.1rem;
  flex-direction: row;
  width: 100%;
}

.searchBar_wrapper {
  display: flex;
  flex-direction: column;
}

.itemSearch {
  display: flex;
  gap: 1rem;
  padding: 0.3rem 0.3rem;
  text-decoration: none;
  border-bottom: 0.5px solid grey;
  flex-direction: row;
}

.searchItems a {
  width: 100%;
  text-decoration: none;
}

.itemSearch img {
  width: 45px;
  height: 45px;
  margin-left: 0.5rem;
  border-radius: 50%;
  aspect-ratio: 1;
}

.search_result_text p {
  margin: 0;
  font-family: "Roboto";
  font-size: 12px;
  text-decoration: none;
  color: var(--text-grey);
}

.search_result_text h6 {
  margin: 0;
  color: var(--text-grey);
}
.serachBxx {
  position: relative;
  height: 100%;
  width: 100%;
}
.searchItems {
  border-radius: 8px;
  width: 100%;
  position: absolute;
  background-color: var(--white);
  z-index: 99999999999;
  max-height: 165px;
  overflow: hidden;
  overflow-y: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.drop_down_searchBar {
  position: relative;
  margin-top: 0.5rem;

  background-color: var(--white);
}
