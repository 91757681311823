.profile_pafe_form input {
  border-top: none;
  border-right: none;
  box-shadow: none !important;
  border-left: none;
  border-radius: 0rem;
  border-bottom: 1.3px solid black;
  margin-bottom: 0.2rem;
  font-weight: 500;
  font-family: "Roboto";
}

.profile_pafe_form label {
  margin: 0 !important;
}

.birth_date_profile {
  margin-top: auto;
}

#phoneNumberProfilePage {
  width: 100%;
}

.react-international-phone-input-container .react-international-phone-input {
  width: 100%;
}

.ProfileCont {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile_section {
  padding: 1.5rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-top: 3rem;
  border-radius: 10px;
}

.radio_buttons_reg_form {
  display: flex;
}

.profile_section label {
  margin-top: 0.5rem !important;
}

.radio_buttons_reg_form input[type="radio"] {
  margin-top: 0.5rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
}

.radio_buttons_reg_form input[type="radio"]:before {
  content: "";
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--primary-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.radio_buttons_reg_form input[type="radio"]:checked:before {
  transform: translate(-50%, -50%) scale(1);
}

.radio_buttons_reg_form label {
  margin-left: 5px;
  vertical-align: middle;
}

.error-message {
  font-size: 13px;
  color: red;
}