.venue-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  gap: 0.2rem;
  padding-bottom: 0.5rem;
}

/* @media screen and (max-width: 1200px) {
  .venue-row {
    gap: 1rem;
  }
} */

.venue-item {
  text-align: center;
}

.venue-image {
  width: 120px;
  cursor: pointer;
  height: 120px;
  border-radius: 50%;
  border: 8px solid rgba(241, 241, 241, 1);
  transition: border-color 0.3s;
}

.venue-image:hover {
  border: 8px solid var(--primary-color);
}

.venue-description {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}

.venueCategor_section {
  margin-top: 1rem;
}

.venueCategory_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.venueCategory_heading h6 {
  color: var(--primary-color);
  font-family: "Roboto";
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.venueCategory_heading a {
  text-decoration: none;
  color: var(--text-grey);
  font-family: "Roboto";
  font-size: 14px;
}

.venueCategory_heading a:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.heatImgg {
  top: 20px;
  z-index: 50;
  left: 14.8rem;
  position: absolute;

  button {
    border: none;
    background-color: transparent;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

@media (max-width:576px) {
  .heatImgg {
    top: 20px;
    z-index: 50;
    left: 80%;
    position: absolute;

    button {
      border: none;
      background-color: transparent;
    }

    img {
      height: 30px;
      width: 30px;
    }
  }
}

@media (min-width: 576px) and (max-width: 1399px) {
  .heatImgg {
    top: 20px;
    z-index: 50;
    left: 35%;
    position: absolute;

  }
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
  display: none;
}