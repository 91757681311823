/* browse cities */
.browse_otherCities_section {
  margin-top: 4rem;
}

.browse_otherCities_heading h3 {
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(42, 45, 50, 1);
}

.city-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.city-card {
  padding: 10px;
  max-width: 200px;
  text-align: center;
}

.city-image {
  cursor: pointer;
  width: 100%;
  outline: none;
  box-shadow: none;
  height: 100%;
  border-radius: 10px;
  border: none;
  padding: 5px;
  object-fit: cover;
  aspect-ratio: 1;
}

.city-description {
  margin-top: 10px;
  text-align: center;
  font-family: "Roboto";
  font-weight: 500;
  text-decoration: none !important;
  color: var(--text-black);
  font-size: 16px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--primary-color);
  font-size: 25px;

  background: var(--white);
  border-radius: 50%;
  opacity: 1 !important;
}