:root {
  --primary-color: rgba(245, 134, 52, 1);
  --black: #000000;
  --white: #fff;
  --text-grey: #7c7c7c;
  --green: rgba(38, 126, 62, 1);
  --light-pink: rgba(250, 241, 241, 1);
  --text-black: #2a2d32;
  --border-color: #d8d9db;
  --off-white: #f3f3f3;
  --Secondary-Light-Orange-Color: rgba(244, 164, 105, 1);
  --text-blue: #535969;
}

.App {
  font-family: "Roboto";
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 5px;
  background: var(--border-color);
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

.otp_update,
.otp_section,
.last_section {
  display: none;
}

.width100per {
  width: 100%;
}

.slctOcsnCardimg {
  text-align: center;
}

.slctOcsnCardTxt p {
  font-size: 14px;
  text-align: center;
}

.appearance_revert {
  appearance: revert-layer !important;
  width: 6% !important;
}