.image-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    /* Maintain aspect ratio */
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    /* Placeholder color */
    filter: blur(20px);
    /* Blur effect */
}