.image_gallery_section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0.1rem;
}

@media screen and (min-width: 991px) {

  .height50vh,
  .image_gallery_section {
    height: 50vh !important;
  }
}

.imagegallery_verticle_images {
  display: flex;
  flex-direction: column;
}

.view_more_image_wrapper {
  position: relative;
}

.view_more_image_wrapper::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: left !important;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 0.1rem;
  width: 99%;
  height: 99%;
}

.view_more_image_wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.view_more_image_wrapper p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0.2rem;
  border-radius: 5px;
  white-space: nowrap;
  color: white;
  /* Change color as needed */
}

.image1Veiw {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.parentContainer {
  position: relative;
  display: flex;
}

.sticky-container {
  position: sticky;
  top: 78px;
  align-self: flex-start;
  /* Ensures that the sticky container stays at the top */
  padding-top: 1rem;
}

.otherContent {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}

@media (max-width: 991px) {
  .image_gallery_section .d-none {
    display: none !important;
  }

  .image_gallery_section .d-lg-flex {
    display: none !important;
  }

  .image_gallery_section .d-lg-none {
    display: block !important;
  }

  .height50vh,
  .image_gallery_section {
    height: auto;
  }
}

/* venue tags section  */
.venue_tags_section {
  margin-top: 0.5rem;
}

.venue_tags_container {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  overflow: auto;
}

.venue_tag {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 0.1rem 0.3rem;
  font-size: 14px;
  cursor: pointer;
}

.venue_tag:hover {
  background-color: whitesmoke;
}

/* styling react tabs  */
.venue_about_section {
  padding-top: 2rem;
}

.tabs {
  width: 100%;
}

.tab-buttons {
  display: flex;
  margin-bottom: 10px;
  background-color: var(--white);
}

.tab-buttons button {
  flex: 1;
  padding: 10px;
  background: transparent;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid transparent;
}

.tab-buttons button.active {
  border-bottom: 2px solid var(--primary-color);

}

.about_venue_tabContent {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about_venue_tabContent p {
  font-family: "Roboto";
  font-size: 14px;
  color: var(--text-grey);
}

.read-more-section p {
  display: inline-block;
}

.venue_features_section {
  margin-top: 2rem;
}

.read-more-toggle:hover {
  text-decoration: underline;
}

.venue_features_wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-direction: row;
}

.venue_features_wrapper img {
  width: 1.2rem;
}

.venue_features_wrapper p {
  margin: 0;
}

/* -----------------------------________Calendy------------------------------- */

.calenday_modelContainer {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.calendy_modelHead {
  background-color: var(--text-grey);
  padding: 1rem 1.5rem;
  color: var(--white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.calendy_modelHead p {
  margin: 0;
}

.calendy_modelHead h4 {
  margin: 0;
  font-weight: 600;
}

.calenday_modelSubHead {
  background-color: var(--primary-color);
  padding: 0.5rem;
  text-align: center;
}

.calenday_modelSubHead p {
  margin: 0;
  color: var(--white);
  font-size: 14px;
}

.calenday_modelScreen {
  height: 450px;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: top;
}

.eventBox {
  cursor: pointer;
}

.eventBox img {
  width: 100%;
}

.eventBox p {
  color: var(--text-grey);
  font-size: 14px;
  text-align: center;
}

.ocsnDopdown {
  width: 100%;
  height: 40px;
  background-color: var(--off-white);
  padding: 0.5rem 1rem;
  border-radius: 3px;
  color: var(--text-grey);
}

.p-dropdown-items {
  background-color: var(--off-white);
  width: 100%;
  color: var(--text-grey);
}

.p-dropdown-items li {
  padding: 0.3rem 0;
}

.MuiDateCalendar-root {
  width: 100% !important;
}

.calenderDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: var(--text-grey) !important;
}

.timeBox {
  border: 1px solid var(--primary-color);
  border-radius: 7px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  min-height: 60px;
}

.timingLabels {
  display: flex;
}

.timeBox:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.timeBox:hover p {
  color: var(--white);
}

.timeBox h6 {
  font-size: 15px;
  margin: 0;
  margin-bottom: 0.3rem;
}

.timeBox p {
  color: var(--text-grey);
  font-size: 12px;
  margin: 0;
}

.personBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.personInfo {
  width: 100%;
}

.border0 {
  border-radius: 0 !important;
}

.personInfo .react-international-phone-input-container .react-international-phone-country-selector-button,
.personInfo .react-international-phone-input-container .react-international-phone-input {
  border-radius: 0 !important;
}

.personInfo .PhoneloginButton {
  width: 100%;
  border-radius: 0;
  height: 40px;
  margin-top: 1.5rem;
}

.sentOtp {
  color: var(--text-grey);
  font-size: 14px;
}

.sentOtp span {
  color: var(--primary-color);
}

.varifuy p {
  margin-bottom: 1.5rem;
}

.varifuy input {
  margin-bottom: 1.5rem;
}

.thankYou img {
  width: 3rem;
  margin-bottom: 1rem;
}

.thankYou {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.carousel_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.carousel_container {
  position: relative;
  width: 80%;
}

.carousel_container .carousel.carousel-slider .control-arrow {
  display: none;
}

.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.468);
  border-radius: 7px;
  font-size: 1.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalArrows {
  width: 100%;
  position: absolute;
  top: 40%;
  display: flex;
  justify-content: space-between;
}

.ModalArrows button {
  border: transparent;
  background-color: transparent;

  img {
    border-radius: 10px;
    padding: 7px;
    height: 28px;
    width: 28px;
    background-color: rgba(0, 0, 0, 0.468);
  }
}

.rightArrow {
  transform: rotate(180deg);
}

.carousel .thumbs {
  margin-bottom: 0 !important;
}

.carousel .thumbs-wrapper {
  margin: 20px auto !important;
  margin-bottom: 0 !important;
}

.carousel .carousel-status {
  top: 5px !important;
  right: 8px !important;
  display: none !important;
}

.eventSelect img {
  width: 100%;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .venue_features_wrapper {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 665px) {
  .close_button {
    top: 10px;
    padding: 10px;
    width: 0.3rem;
    height: 0.3rem;
  }

  .ModalArrows {
    top: 34%;
  }

  .ModalArrows button {
    border: transparent;
    background-color: transparent;

    img {
      border-radius: 10px;
      padding: 7px;
      height: 23px;
      width: 23px;
      background-color: rgba(0, 0, 0, 0.468);
    }
  }
}