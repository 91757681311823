.list_your_venue_heading h3 {
  color: var(--text-black);
  font-family: "Roboto";
  font-weight: 600;
}

.list_your_venue_heading h4 {
  text-align: left;
  color: var(--text-grey);
  margin-bottom: 1rem;
}

.list_your_venue_heading p {
  text-align: left;
  color: var(--text-grey);
  font-weight: 400;
  font-family: "Roboto";
  line-height: 24px;
  margin-bottom: 1.5rem;
}

.list_your_venue_heading {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.list_your_venue_heading button {
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 13px;
  width: fit-content;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 0.4rem 0.8rem;
}

.list_your_venue_Section {
  padding: 2.5rem 2rem;
  flex-direction: row;
  border-radius: 8px;
  background-color: var(--light-pink);
}

.bg_container_listyourvenue img {
  width: 100%;
}

.statusText {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-black);
  margin-top: 1rem;
}

.statusCOlor {
  padding: 0.2rem 1rem;
  border-radius: 3px;
  color: var(--white);
}

.booked {
  background-color: var(--green);
}

@media (max-width:600px) {
  .list_your_venue_Section {
    padding: 2.5rem 1rem;
  }
}