#homeBG {
  width: 100%;
  height: 50vh;
  border-radius: 20px;
  position: relative;
  object-fit: cover;
}

.heroSection {
  margin-top: 2rem;
}

.heroSection_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.searchBar_container_homeScreen {
  position: absolute;
  width: fit-content;
}

.searchBarInHeroSection {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
}

.Heading_herosection {
  padding: 0.5rem 0.5rem;
  width: 100%;
  flex-wrap: wrap;
}

.contentTestionomial {
  padding: 0.4rem;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  position: absolute;
}

.SliderArrows {
  position: absolute;
  z-index: 20;
  top: 45%;
  display: flex;
  width: 100%;
  justify-content: space-between;

  button {
    background-color: transparent;
    border: none;
  }

  img {
    padding: 2px;
    border-radius: 50%;

    height: 30px;
    width: 30px;
  }
}

.leftArrow {
  position: absolute;
  left: 0rem;
  background-color: #c7bfbd;
  transform: rotate(180deg);
}

.RightArrow {
  position: absolute;
  right: -2rem;
  background-color: var(--primary-color);
}

.leftRightArrows {
  position: absolute;
  top: 45%;
  display: flex;

  width: 100%;
  z-index: 50;
  justify-content: space-between;

  button {
    background-color: transparent;
    border: transparent;
  }
}

.leftButton {
  position: absolute;
  left: -5rem;
}

.RightButtonArrow {
  position: absolute;
  right: 0rem !important;
}

@media screen and (max-width: 768px) {
  .listVenueDiv {
    margin: 0 !important;
  }

  .paddingAfter768 {
    padding: 0;
  }

  .comment-section {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .Heading_herosection {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .venue-image {
    width: 80px !important;
    height: 80px !important;
  }

  .venue-description {
    font-size: 14px !important;
  }

  .venue-row {
    gap: 0.6rem;
  }

  .seAll_span {
    display: none !important;
  }

  .cities_mapped .slick-prev {
    top: 35% !important;
  }

  .cities_mapped .slick-next {
    top: 35% !important;
  }

  .cententCarausel h6 {
    font-size: 14px !important;
  }

  .cententCarausel p {
    font-size: 12px !important;
  }
}

.padding0 {
  padding: 0 !important;
}

.Heading_herosection h1 {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--white);
  font-family: "Roboto";
  font-weight: 600;
}

/* venue categories section */
/* VenueCategories.css */

/* popular venues section css */
.popularVenues_section {
  margin-top: 3rem;
}

.popularVenues_venue_container {
  padding: 0.6rem 0.6rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  font-family: "Roboto";
  background: rgba(245, 245, 245, 1);
  transition: ease-in 0.2s;
}

.popularVenues_venue_container:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.popularVenues_heading_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.popularVenues_heading_container h5 {
  color: var(--text-grey);
  font-weight: 400;
  margin: 0;
}

.popularVenues_heading_container h3 {
  color: rgba(42, 45, 50, 1);
  font-weight: 600;
}

.popularVenues_heading_container a {
  text-decoration: none;
  color: var(--text-grey);
  font-family: "Roboto";
  font-size: 14px;
}

.popularVenues_heading_container a:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.venue_image_holder img {
  width: 100%;
  border-radius: 5px;
  max-height: 185px;
  aspect-ratio: 1.5;
}

.venue_category_titles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--primary-color);
  font-weight: 500;
  gap: 0.2rem;
  font-size: 14px;
}

.venue_category_div {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.venue_category_div p {
  margin-bottom: 0;
}

.rating_greenDiv {
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border-radius: 4px;
  padding: 0.1rem 0.3rem;
  flex-direction: row;
  color: var(--white);
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 700;
  background-color: var(--green);
}

.rating_greenDiv img {
  height: 15px;
  width: 15px;
}

.venue_capacity_wrapper {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.venue_capacity_wrapper p {
  color: var(--text-grey);
  margin: 0;
}

.venue_address_wrapper p {
  color: var(--text-grey);
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.venue_address_wrapper h6 {
  font-size: 16px;
  color: var(--text-black);
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.venue_cards_container {
  display: flex;
  flex-wrap: wrap;
}

.pagination_controls {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
}

.pagination_controls button {
  background-color: var(--white);
  border: 1px solid rgba(236, 237, 239, 1);
  border-radius: 4px;
  aspect-ratio: 1;
  cursor: pointer;
  width: 40px;
}

.pagination_controls button:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.seAll_span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.seAll_span p {
  margin: 0;
}

/* discover more section  */
.discover_more_venues_section {
  margin-top: 4rem;
}

.discoverMore_container {
  background-color: var(--light-pink);
  border-radius: 10px;
}

.discoverMore_containerText {
  padding: 0.5rem 1.2rem;
}

.discoverMore_container img {
  width: 100%;
  margin-bottom: 0.4rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.discoverMore_container p {
  font-size: 14px;
  color: var(--text-black);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.discoverMore_container h6 {
  padding-top: 0.5rem;
  font-size: 21px;
  margin-bottom: 0.5rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.discover_more_venues_heading h3 {
  font-family: "Roboto";
  font-weight: 600;
  color: var(--text-black);
}

.verticle_container_discoverMore {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.smaller_container_discoverMore {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.smaller_container_discoverMore p {
  margin: 0;
}

.smaller_container_discoverMore hr {
  margin: 0;
}

.smaller_container_discoverMore img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.heading_discoverMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
  gap: 0.5rem;
  width: 100%;
}

.heading_discoverMore h6 {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.heading_discoverMore p {
  color: var(--primary-color);
  font-size: 14px;
}

/* how it works  */
.howitworks_section {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.howitworks_cards_section {
  margin-top: 3rem;
}

.howitworks_cards_heading h3 {
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(42, 45, 50, 1);
}

.smartcArds {
  display: flex;
  gap: 1.5rem;
  text-align: center;
}

.cententCarausel img {
  width: 80px;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 0.6rem;
}

.cententCarausel h6 {
  color: rgba(42, 45, 50, 1);
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  margin: 0;
}

.cententCarausel p {
  font-size: 14px;
  width: 80%;
}

@media screen and (max-width: 1100px) {
  .cententCarausel p {
    font-size: 14px;
    width: 100%;
  }
}

.cententCarausel {
  color: var(--text-grey);
  font-family: "Roboto";
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

/* testimonials css */

.carousel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.carousel-button {
  background-color: transparent;
  border: none;
  width: 4.5rem;
  color: var(--white);
  cursor: pointer;
}

.carousel-button img {
  width: 100%;
}

.carousel-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.comment-section {
  flex: 2;
  padding: 0 20px;
}

.comment {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.author {
  font-weight: bold;
  font-size: 1rem;
  color: var(--white);
}

.testimonial_section {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial_wrapper {
  width: 100%;
}

.testimonial_background_container {
  width: 100%;
  height: 90vh;
  background-image: url("../../Assets/testimonial_bg.png");
}

@media screen and (max-width: 991px) {
  .testimonial_background_container {
    height: 70vh;
  }
}

@media screen and (max-width: 768px) {
  .testimonial_Container {
    width: 100%;
  }

  #right_button_img_testimonaial {
    display: none;
  }

  .comment-section {
    padding: 0 !important;
  }
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial_Container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.carousel-button {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: var(--white);
  cursor: pointer;
}

.carousel-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.profile-section .bgImge {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
}

.profile-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bgImge2 {
  position: absolute;
  top: -1%;
  left: -5%;
}

@media screen and (min-width: 1400px) {
  .bgImge2 {
    position: absolute;
    top: -1%;
    left: -5%;
    width: 180px !important;
  }
}

@media screen and (max-width: 1400px) {
  .bgImge2 {
    position: absolute;
    top: -1%;
    left: -5%;
    width: 150px !important;
  }
}

@media screen and (max-width: 1200px) {
  .bgImge2 {
    position: absolute;
    top: -1%;
    left: -5%;
    width: 120px !important;
  }
}

@media screen and (max-width: 991px) {
  .comment-section h2 {
    font-size: 20px !important;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .bgImge2 {
    position: absolute;
    top: 0%;
    left: -1%;
    width: 100px !important;
  }
}

@media screen and (max-width: 576px) {
  .comment-section h2 {
    font-size: 20px !important;
    margin-bottom: 1rem;
  }

  .comment-section p {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 450px) {
  .bgImge2 {
    position: absolute;
    top: 0%;
    left: -1%;
    width: 70px !important;
  }
}

.personImg {
  position: absolute !important;
  top: 11%;
  left: 10%;
  width: 70% !important;
  padding: 6px;
  border-radius: 50%;
  background-color: var(--white);
}

.comment-section {
  flex: 2;
  padding: 0 20px;
  text-align: left;
}

.comment-section h2 {
  color: var(--white);
  margin-bottom: 1.5rem;
}

.comment {
  font-size: 1.2rem;
  color: var(--white);
  margin-bottom: 10px;
}

.author {
  color: var(--primary-color) !important;
  font-size: 24px !important;
  margin-top: 2rem;
}

.venueDetailCOntainer {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.cities_mapped .slick-next {
  right: -5px;
  top: 42%;
  z-index: 2;
}

.cities_mapped .slick-prev {
  left: -12px;
  top: 42%;
  z-index: 2;
}

.listVenueDiv {
  position: relative;
  margin-top: -6rem;
  z-index: 1;
}

.howitworks_cards_heading h2,
.discover_more_venues_heading h2,
.browse_otherCities_heading h2 {
  margin-bottom: 2rem;
}

.list_your_venue_heading h2,
.howitworks_cards_heading h2,
.discover_more_venues_heading h2,
.popularVenues_heading_container h2,
.browse_otherCities_heading h2 {
  font-weight: 600;
}

.facility_item img {
  width: 1.2rem;
  height: 1.2rem;
}


.marquee {
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow: hidden;
  /* Hide any overflow */
  animation: marquee 20s linear infinite;
  /* Use CSS animation for scrolling */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  /* Start at 0 position */
  100% {
    transform: translateX(-100%);
  }

  /* Move to -100% position (offscreen to the left) */
}